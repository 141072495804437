export default function MuiSvgIcon() {
	return {
		root: {
			height: '22px',
			width: '22px',
			fontSize: '22px'
		},
		fontSizeLarge: {
			height: '32px',
			width: '32px',
			fontSize: '32px'
		},
		fontSizeSmall: {
			height: '12px',
			width: '12px',
			fontSize: '12px'
		}
	};
}
