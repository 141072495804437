import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth, Hub, Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import amplifyConfig from '../amplifyConfig';
import { getUserProfile, getCurrentTenant } from '../services/api/userService';

const AuthContext = createContext();

const TENANT_SUCCESS = 'done';

export const useAuth = () => {
	return useContext(AuthContext);
};

function AuthProvider({ children }) {
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		Amplify.configure(amplifyConfig);
		checkUser();
		const handleSignOut = () => {
			setUser(null);
			setAuthenticated(false);
		};

		const authListener = Hub.listen('auth', ({ payload: { event } }) => {
			switch (event) {
				case 'signIn':
					return checkUser();
				case 'signOut':
					return handleSignOut();
				default:
					break;
			}
		});

		return () => authListener();
	}, []);

	const checkUser = async () => {
		try {
			const cognitoData = await Auth.currentAuthenticatedUser({ bypassCache: true });
			const userData = await getUserProfile();
			const tenantData = await getCurrentTenant();
			setInitialized(true);
			if (userData?.status !== 200 || tenantData?.data?.init_status !== TENANT_SUCCESS) {
				navigate('/auth/account-info');
				return;
			}
			setUser({
				...cognitoData.attributes,
				role: userData?.data?.role
			});
			setAuthenticated(true);
		} catch (err) {
			setUser(null);
			setAuthenticated(false);
			setInitialized(true);
		}
	};

	const forceRefreshSession = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const session = await Auth.currentSession();

			// Force refresh the tokens
			const newSession = await new Promise((resolve, reject) => {
				user.refreshSession(session.getRefreshToken(), (err, newSession) => {
					if (err) {
						reject(err);
					} else {
						resolve(newSession);
					}
				});
			});

			// Manually set the new session tokens
			user.signInUserSession = newSession;

			// Update the Cognito User Pool local storage
			const userCopy = { ...user, signInUserSession: newSession };
			Auth.storeUserData(userCopy);
			return newSession;
		} catch (error) {
			console.log('Error refreshing token:', error);
		}
	};

	const signIn = async (username, password) => {
		const response = await Auth.signIn(username, password);
		if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
			console.log('New password required', response);
			setUser(response);
		}
		return response;
	};

	const signUp = async (username, password, email) => {
		const response = await Auth.signUp({
			username,
			password,
			attributes: { email }
		});

		if (typeof response.userConfirmed !== 'undefined' && response.userConfirmed === false) {
			console.log('User not confirmed', response);
			response.confirmUser = true;
		}
		return response;
	};

	const signOut = async () => {
		try {
			await Auth.signOut();
		} catch (err) {
			console.error('Error signing out', err);
		}
	};

	const forgotPassword = async username => {
		return Auth.forgotPassword(username);
	};

	const submitForgotPassword = async (username, code, newPassword) => {
		return Auth.forgotPasswordSubmit(username, code, newPassword);
	};

	const completeNewPassword = async newPassword => {
		try {
			await Auth.completeNewPassword(user, newPassword);
		} catch (err) {
			console.error('Error completing new password', err);
		}
	};

	const confirmSignup = async (code, usr) => {
		const confirmUser = Auth.confirmSignUp(usr, code);
		return confirmUser;
	};

	const resendConfirmationCode = async username => {
		return Auth.resendSignUp(username ?? user.email);
	};

	const changePassword = async (oldPassword, newPassword) => {
		const currentUser = await Auth.currentAuthenticatedUser();
		return Auth.changePassword(currentUser, oldPassword, newPassword);
	};

	const getAccessToken = async () => {
		return (await Auth.currentSession()).getIdToken().getJwtToken();
	};

	return (
		<AuthContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				user: {
					displayName: user?.email,
					role: user?.role,
					isSuperadmin: user?.['custom:superuser'] === 'true',
					tenant: user?.['custom:tenant'],
					email: user?.email,
					...user
				},
				method: process.env.REACT_APP_FEDERATED_ID ? 'SSO' : 'login',
				isAuthenticated: authenticated,
				isInitialized: initialized,
				code: user?.challengeName,
				login: signIn,
				register: signUp,
				logout: signOut,
				resetPassword: forgotPassword,
				submitForgotPassword,
				completeNewPassword,
				resendConfirmationCode,
				validateUserSession: checkUser,
				refreshSession: forceRefreshSession,
				confirmSignup,
				changePassword,
				getAccessToken
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
