// ----------------------------------------------------------------------

export default function MuiContainer() {
	return {
		MuiContainer: {
			styleOverrides: {
				root: {
					marginTop: '50px',
					paddingBottom: '50px'
				}
			}
		}
	};
}
