import { Auth } from 'aws-amplify';
import CONFIGURATION from './app_configuration';

const amplifyConfig = {
	Auth: {
		identityPoolId: CONFIGURATION.AWS_COGNITO_IDENTITY_POOL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
		region: CONFIGURATION.AWS_REGION, // REQUIRED - Amazon Cognito Region
		userPoolId: CONFIGURATION.AWS_COGNITO_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
		userPoolWebClientId: CONFIGURATION.AWS_COGNITO_CLIENT_ID // OPTIONAL - Amazon Cognito Web Client ID
	},
	oauth: {
		region: CONFIGURATION.AWS_REGION,
		domain: `nola-users-${CONFIGURATION.ENVIRONMENT}.auth.${CONFIGURATION.AWS_REGION}.amazoncognito.com`,
		scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
		redirectSignIn: window.location.origin,
		redirectSignOut: window.location.origin,
		responseType: 'code'
	},
	Analytics: {
		disabled: true
	},
	API: {
		endpoints: [
			{
				name: CONFIGURATION.AWS_API_NAME,
				endpoint: CONFIGURATION.AWS_API_URL,
				service: CONFIGURATION.AWS_API_SERVICE,
				region: CONFIGURATION.AWS_REGION,
				custom_header: async () => {
					return {
						Authorization: `Bearer ${(await Auth.currentSession())
							.getIdToken()
							.getJwtToken()}`
					};
				}
			},
			{
				name: CONFIGURATION.AWS_CHATAPI_NAME,
				endpoint: CONFIGURATION.AWS_CHATAPI_URL,
				service: CONFIGURATION.AWS_API_SERVICE,
				region: CONFIGURATION.AWS_REGION,
				custom_header: async () => {
					return {
						Authorization: `Bearer ${(await Auth.currentSession())
							.getIdToken()
							.getJwtToken()}`
					};
				}
			}
		]
	},
	Storage: {
		AWSS3: {
			level: 'public',
			bucket: 'ev-pre-prod-profile-pics', // REQUIRED -  Amazon S3 bucket
			region: 'us-east-1' // OPTIONAL -  Amazon service region
		}
	}
};

export default amplifyConfig;
