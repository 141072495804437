export default function MuiFab(theme) {
	return {
		root: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.text.secondary,
			'&:hover': {
				backgroundColor: theme.palette.common.neutral
			}
		}
	};
}
