// material
import { styled } from '@mui/material/styles';
import { Container, Button } from '@mui/material';
// hooks
import { Auth } from 'aws-amplify';
import useAuth from '../../hooks/useAuth';
// components;
import LoginForm from './LoginForm';
import SetNewPasswordForm from './SetNewPasswordForm';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
	maxWidth: 480,
	margin: 'auto',
	display: 'flex',
	minHeight: '100vh',
	flexDirection: 'column',
	justifyContent: 'center'
}));

// ----------------------------------------------------------------------

export default function Login() {
	const { method, code } = useAuth();

	const handleSSOClick = async provider => {
		try {
			await Auth.federatedSignIn({ provider });
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<Container sx={{ mt: 0, pb: 0 }} maxWidth="sm">
			<ContentStyle>
				<img
					alt="Responsum Logo"
					style={{ width: 250, margin: 'auto', marginBottom: 50, marginTop: -50 }}
					src="/images/logos/logo-full-dark-background.png"
				/>
				{code === 'NEW_PASSWORD_REQUIRED' ? (
					<SetNewPasswordForm />
				) : method !== 'SSO' ? (
					<LoginForm />
				) : (
					<Button
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						onClick={handleSSOClick}
					>
						Login
					</Button>
				)}
			</ContentStyle>
		</Container>
	);
}
