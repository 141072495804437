import { useContext } from 'react';
import { AuthContext } from '../contexts/AwsCognitoContext';
import { AzureAuthContext } from '../contexts/AzureContext';
import config from '../app_configuration';

// ----------------------------------------------------------------------

const provider = {
	AWS: AuthContext,
	azure: AzureAuthContext
};

const useAuth = () => useContext(provider[config.VENDOR]);

export default useAuth;
