import { Link as Router } from 'react-router-dom';
import { Container, Alert, AlertTitle, Button } from '@mui/material';
import useAuth from '../hooks/useAuth';

export default function RoleBasedGuard({ children, accessibleRoles, superadminRoute }) {
	const { user } = useAuth();
	const currentRole = user?.role;

	const showPermissionDenied =
		(!user.isSuperadmin && superadminRoute) ||
		(!superadminRoute && !accessibleRoles.includes(currentRole));

	if (showPermissionDenied) {
		return (
			<Container>
				<Alert variant="outlined" severity="error">
					<AlertTitle>Permission Denied</AlertTitle>
					You do not have permission to access this page <br />
					<Button
						to="/"
						LinkComponent={Router}
						size="small"
						sx={{ mt: 2, ml: 'auto' }}
						variant="contained"
					>
						Take me Home
					</Button>
				</Alert>
			</Container>
		);
	}

	return children;
}
