import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from '../layouts/Dashboard/index';
import Loader from '../components/Loader';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import RoleGuard from '../guards/RoleBasedGuard';
import useAuth from '../hooks/useAuth';
import { ADMIN_ROLE, USER_ROLE } from '../constants/index';

const Loadable = Component =>
	function WithSuspense(props) {
		return (
			<Suspense fallback={<Loader />}>
				<Component {...props} />
			</Suspense>
		);
	};

// code splitted components
/* const RolesAndPermissions = Loadable(
	lazy(() => lazyRetry(() => import('../views/RolesAndPermissions')))
); */
const AgentManagerSuperadmin = Loadable(
	lazy(() => lazyRetry(() => import('../views/AgentManagerSuperadmin')))
);
const AgentManager = Loadable(lazy(() => lazyRetry(() => import('../views/AgentManager'))));
const AgentConfig = Loadable(
	lazy(() => lazyRetry(() => import('../views/AgentManager/AgentConfigurator')))
);
const AgentConfigurator = Loadable(
	lazy(() => lazyRetry(() => import('../views/AgentManagerSuperadmin/AgentConfigurator')))
);
const ClientAgents = Loadable(
	lazy(() => lazyRetry(() => import('../views/AgentManagerSuperadmin/ClientAgents')))
);
const SignIn = Loadable(lazy(() => lazyRetry(() => import('../views/SignIn/SignIn'))));
const SignUp = Loadable(lazy(() => lazyRetry(() => import('../views/SignUp/SignUp'))));
const Onboarding = Loadable(lazy(() => lazyRetry(() => import('../views/Onboarding'))));
const Chat = Loadable(lazy(() => lazyRetry(() => import('../views/Chat'))));
const Landing = Loadable(lazy(() => lazyRetry(() => import('../views/Landing'))));
const DocumentsManager = Loadable(lazy(() => lazyRetry(() => import('../views/DocumentsManager'))));
const ConnectionEnable = Loadable(lazy(() => lazyRetry(() => import('../views/ConnectionEnable'))));
const Profile = Loadable(lazy(() => lazyRetry(() => import('../views/Profile/Profile'))));
const NotFound = Loadable(lazy(() => lazyRetry(() => import('../views/NotFound/NotFound'))));
const Users = Loadable(lazy(() => lazyRetry(() => import('../views/Users/Users'))));
const Monitoring = Loadable(lazy(() => lazyRetry(() => import('../views/Monitoring'))));
const Redirect = Loadable(lazy(() => lazyRetry(() => import('../components/Redirect'))));
const ForgotPassword = Loadable(
	lazy(() => lazyRetry(() => import('../views/ForgotPassword/ResetPassword')))
);
const Testing = Loadable(lazy(() => lazyRetry(() => import('../views/Testing'))));
const LlmManager = Loadable(lazy(() => lazyRetry(() => import('../views/LlmManager'))));
const LlmConfigurator = Loadable(
	lazy(() => lazyRetry(() => import('../views/LlmManager/LlmConfigurator')))
);

const lazyRetry = componentImport => {
	return new Promise((resolve, reject) => {
		const hasRefreshed = JSON.parse(
			window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
		);

		componentImport()
			.then(component => {
				window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
				resolve(component);
			})
			.catch(error => {
				if (!hasRefreshed) {
					window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
					return window.location.reload();
				}
				reject(error);
				return null;
			});
	});
};

export default function Router() {
	const { isAuthenticated } = useAuth();
	// when authenticated, default route is based on permissions, when not, redirect to login
	const getDefaultRoute = () => {
		if (isAuthenticated) {
			return <Navigate to="/dashboard" replace />;
		}
		return <Navigate to="/auth/login" replace />;
	};

	return useRoutes([
		{
			path: 'auth',
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard defaultRoute="/">
							<SignIn />
						</GuestGuard>
					)
				},
				{
					path: 'register',
					element: (
						<GuestGuard defaultRoute="/">
							<SignUp />
						</GuestGuard>
					)
				},
				{
					path: 'confirm-email',
					element: (
						<GuestGuard defaultRoute="/">
							<SignUp confirm />
						</GuestGuard>
					)
				},
				{
					path: 'reset-password',
					element: (
						<GuestGuard>
							<ForgotPassword />
						</GuestGuard>
					)
				},
				{
					path: 'account-info',
					element: (
						<GuestGuard defaultRoute="/">
							<Onboarding />
						</GuestGuard>
					)
				}
			]
		},
		{
			path: 'dashboard',
			element: (
				<AuthGuard>
					<Dashboard />
				</AuthGuard>
			),
			children: [
				{ path: '', element: <Landing /> },
				{ path: 'profile', element: <Profile /> },
				{
					path: 'docs',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE]}>
							<DocumentsManager />
						</RoleGuard>
					)
				},
				{
					path: 'docs/:folderPath',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE]}>
							<DocumentsManager />
						</RoleGuard>
					)
				},
				{
					path: 'users',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE]}>
							<Users />
						</RoleGuard>
					)
				},
				{
					path: 'agents',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE]}>
							<AgentManager />
						</RoleGuard>
					)
				},
				{
					path: 'agents/:id',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE]}>
							<AgentConfig />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/agent-manager',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<AgentManagerSuperadmin />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/feedback',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<Monitoring />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/testing',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<Testing />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/llm-manager',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<LlmManager />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/llm-manager/:id',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<LlmConfigurator />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/agent-manager/:client',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<ClientAgents />
						</RoleGuard>
					)
				},
				{
					path: 'superadmin/agent-manager/:client/:id',
					element: (
						<RoleGuard superadminRoute accessibleRoles={[ADMIN_ROLE]}>
							<AgentConfigurator />
						</RoleGuard>
					)
				},
				{
					path: 'chat/:id',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE, USER_ROLE]}>
							<Chat />
						</RoleGuard>
					)
				},
				{
					path: 'chat/:id/:sessionId',
					element: (
						<RoleGuard accessibleRoles={[ADMIN_ROLE, USER_ROLE]}>
							<Chat />
						</RoleGuard>
					)
				}
			]
		},
		{
			path: '/connection-enabled/:connection_id',
			element: (
				<AuthGuard>
					<ConnectionEnable />
				</AuthGuard>
			)
		},
		{
			path: '/redirect',
			element: (
				<GuestGuard defaultRoute="/">
					<Redirect />
				</GuestGuard>
			)
		},
		{
			path: '/',
			element: getDefaultRoute()
		},
		{
			path: '/not-found',
			element: <NotFound />
		},
		{
			path: '*',
			element: <Navigate to="/not-found" replace />
		}
	]);
}
