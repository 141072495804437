import { useState } from 'react';
import { Alert, List, Box, IconButton, Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import { removeError } from '../../redux/actions/commonActions';
import { LEFT_SIDEBAR_WIDTH } from '../../constants';

function ErrorStack() {
	const dispatch = useDispatch();
	const [indexToDelete, setIndexToDelete] = useState();
	const errors = useSelector(state => state.common.errors);
	const sidebarOpened = useSelector(state => state.layout.sidebarIsOpen);
	const margin = sidebarOpened ? LEFT_SIDEBAR_WIDTH : 0;

	const handleRemoveErrorClick = index => {
		setIndexToDelete(index);
	};

	const handleDelete = index => {
		setIndexToDelete(false);
		dispatch(removeError(index));
	};

	return (
		<Box
			sx={{
				left: margin,
				bottom: 0,
				position: 'fixed',
				width: `calc(100% - ${margin}px)`,
				zIndex: 9999
			}}
		>
			<List sx={{ pl: 2, pr: 2 }}>
				<TransitionGroup>
					{errors?.map((err, index) => (
						<Collapse
							in={!(indexToDelete === index)}
							onAnimationEnd={() => handleDelete(index)}
						>
							<Alert
								action={
									<IconButton
										color="inherit"
										size="small"
										onClick={() => handleRemoveErrorClick(index)}
									>
										<CloseIcon />
									</IconButton>
								}
								sx={{ mb: 1 }}
								variant="filled"
								color="error"
								severity="error"
							>
								{err.text}
							</Alert>
						</Collapse>
					))}
				</TransitionGroup>
			</List>
		</Box>
	);
}

export default ErrorStack;
