import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import {
	disconnectSocket,
	connectSocket,
	emitMessage,
	removeSubscription,
	subscribeToEvent,
	initiateSocket
} from '../socketio.service';
import useAuth from './useAuth';
import {
	setChatbotConnected,
	setIsStreaming,
	updateMessageWithToken,
	updateHistory
} from '../redux/actions/chatbotActions';

export default function useSocketEvents() {
	const dispatch = useDispatch();
	const { getAccessToken, refreshSession } = useAuth();
	const { id, sessionId } = useParams('id');

	const [pendingMessages, setPendingMessages] = useState([]);

	const isConnected = useSelector(state => state.chatbot.isConnected);

	const isConnectedRef = useRef(null);

	useEffect(() => {
		if (pendingMessages.length) {
			pendingMessages.forEach(message => emitSocketMessage(message.key, message.payload));
			setPendingMessages([]);
		}
	}, [id]);

	useEffect(() => {
		isConnectedRef.current = isConnected;
	}, [isConnected]);

	function socketStatusCallback(response, payload, key) {
		console.log(response, payload, key);
		if (!response.success && response.error === 'JWTExpiredError') {
			setPendingMessages([...pendingMessages, { key, payload }]);
			refreshSession();
			return;
		}
		if (!response.success) {
			handleError();
		}
	}

	function onConnect() {
		dispatch(setChatbotConnected(true));
	}

	function getHistorySnapshot(last_message_ts, snapshot_size) {
		emitSocketMessage('request_agent_history_snapshot', { last_message_ts, snapshot_size });
	}

	function onDisconnect(reason, details) {
		console.log('reason', reason, details);
		dispatch(setChatbotConnected(false));
	}

	function sendMessageToAgent(message, isKbAgent) {
		if (isKbAgent) {
			emitSocketMessage('send_message_to_kb_agent', {
				message,
				chat_method: 'search'
			});
		} else {
			emitSocketMessage('send_message_to_agent', {
				message
			});
		}
	}

	async function emitSocketMessage(key, payload) {
		emitMessage(
			key,
			id,
			sessionId,
			{ ...payload, token: await getAccessToken() },
			socketStatusCallback
		);
	}

	const handleError = () => {
		console.log('handle error');
		dispatch(setIsStreaming(false));
		enqueueSnackbar('Error while performing action', { variant: 'error' });
	};

	function getWelcomeMessage() {
		emitSocketMessage('request_agent_welcome_message');
	}

	function handleHistoryLoad(snapshot) {
		dispatch(updateHistory(snapshot));
	}

	function handleIncomingTokenStream(event) {
		console.log('handleIncomingTokenStream', event);
		dispatch(updateMessageWithToken(event));
	}

	function handleFinalMessageUpdate(event) {
		console.log('handleFinalIncomingTokenStream', event);
		dispatch(updateMessageWithToken(event, true));
	}

	function handleFeedbackSubmit(feedbackData) {
		emitSocketMessage('post_agent_message_feedback', feedbackData);
	}

	async function reconnectSocket(e) {
		if (!e.target.hidden && !isConnectedRef.current) {
			connectSocket(await getAccessToken());
		}
	}

	const initSocket = useCallback(async () => {
		await initiateSocket(await getAccessToken());
		subscribeToEvent('connect', onConnect);
		subscribeToEvent('disconnect', onDisconnect);
		subscribeToEvent('agent_sends_history_snapshot', handleHistoryLoad);
		subscribeToEvent('agent_streams_message', handleIncomingTokenStream);
		subscribeToEvent('agent_updates_history_item', handleFinalMessageUpdate);
		subscribeToEvent('connect_error', error => {
			console.error('Connection error:', error);
		});
		subscribeToEvent('connect_timeout', timeout => {
			console.error('Connection timed out:', timeout);
		});
		document.addEventListener('visibilitychange', reconnectSocket);
	}, []);

	const detachListeners = () => {
		removeSubscription('disconnect', onDisconnect);
		removeSubscription('agent_sends_history_snapshot', handleHistoryLoad);
		removeSubscription('agent_streams_message', handleIncomingTokenStream);
		removeSubscription('agent_updates_history_item', handleFinalMessageUpdate);
		document.removeEventListener('visibilitychange', reconnectSocket);
		disconnectSocket();
	};

	return {
		initSocket,
		detachSocketListeners: detachListeners,
		sendMessageToAgent,
		getHistorySnapshot,
		handleFeedbackSubmit,
		getWelcomeMessage
	};
}
