// Colors
import { primary } from '../../common/colors';

export default function MuiFilledInput(theme) {
	return {
		root: {
			backgroundColor: theme.palette.background.default,
			'&:hover': {
				backgroundColor: primary.light
			},
			'&$focused': {
				backgroundColor: primary.light
			}
		}
	};
}
