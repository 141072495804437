import { LEFT_SIDEBAR_WIDTH } from '../../constants';

const styles = theme => ({
	topbar: {
		position: 'fixed',
		width: '100%',
		top: 0,
		left: 0,
		right: 'auto',
		/* backgroundColor: theme.palette.background.mainDark, */
		transition: theme.transitions.create(['padding', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	bugButton: {
		backgroundColor: theme.palette.common.white,
		position: 'fixed',
		right: '0px',
		top: '240px',
		borderRadius: '8px 0 0 8px',
		border: `1px solid ${theme.palette.primary.main}`,
		textAlign: 'center'
	},
	bugTextField: {
		width: '100%'
	},
	leftTopbarShift: {
		paddingLeft: '201px',
		width: '100%'
	},
	rightTopbarShift: {
		marginRight: '201px'
	},
	leftRightTopbarShift: {
		paddingRight: '201px'
	},
	drawerPaper: {
		flexDirection: 'inherit',
		overflowY: 'visible!important'
	},
	content: {
		minHeight: '100%',
		marginTop: 0,
		backgroundColor: theme.palette.background.dark,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0
		}
	},
	leftContentShift: {
		marginLeft: LEFT_SIDEBAR_WIDTH
	},
	progressWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center' /* Centering y-axis */,
		alignItems: 'center',
		height: '95vh'
	}
});

export default styles;
