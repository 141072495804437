export default function MuiAlert(theme) {
	return {
		MuiAlert: {
			styleOverrides: {
				outlinedError: {
					border: `1px solid ${theme.palette.error.main}`
				}
			}
		}
	};
}
