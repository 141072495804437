import {
	FETCH_MY_CONTACTS,
	REINVITE_USER,
	CHANGE_USER_ROLE,
	DELETE_USER,
	DELETE_USER_ERROR,
	INVITE_USER_PENDING,
	INVITE_USER_FAILURE,
	INVITE_USER_SUCCESS
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case INVITE_USER_PENDING: {
			return { ...state, error: null, userInvited: false, message: null };
		}
		case INVITE_USER_SUCCESS: {
			return { ...state, error: null, userInvited: true, message: action.payload.message };
		}
		case INVITE_USER_FAILURE: {
			return { ...state, error: action.payload.message, userInvited: false };
		}
		case FETCH_MY_CONTACTS: {
			const returnProps = {
				...state,
				myUsers: action.payload?.data,
				code: action.payload.status
			};
			return returnProps;
		}
		case REINVITE_USER: {
			return {
				...state,
				reinviteUserApiResponse: action.payload.status
			};
		}
		case DELETE_USER: {
			return {
				...state,
				status: action.payload.status
			};
		}
		case DELETE_USER_ERROR: {
			return {
				...state,
				error: 'Error deleting user. Please try again.'
			};
		}
		case CHANGE_USER_ROLE:
			return {
				...state,
				changeUserRoleApiResponse: action.payload.status
			};
		default:
			return state;
	}
}
