import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function MuiListItemButton(theme) {
	return {
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: theme.palette.text.secondary,
					'&.Mui-selected': {
						backgroundColor: alpha(theme.palette.secondary.main, 0.07),
						color: theme.palette.secondary.main,
						'&:hover': {
							backgroundColor: alpha(theme.palette.secondary.main, 0.1)
						}
					}
				}
			}
		}
	};
}
