import { AuthProvider } from '../contexts/AwsCognitoContext';
import { AzureAuthProvider } from '../contexts/AzureContext';
import CONFIGURATION from '../app_configuration';

const provider = {
	AWS: AuthProvider,
	azure: AzureAuthProvider
};

export const useAuthProvider = () => {
	// Return the appropriate provider
	return provider[CONFIGURATION.VENDOR];
};
