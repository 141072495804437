export default function MuiTab(theme) {
	return {
		MuiTab: {
			styleOverrides: {
				root: {
					height: '50px',
					textTransform: 'none',
					fontSize: '14px',
					minWidth: '600px',
					padding: theme.spacing(3),
					'@media (min-width: 960px)': {
						minWidth: '100px'
					},
					'&$selected': {
						fontWeight: 500
					}
				},
				label: {},
				labelContainer: {},
				textColorPrimary: {
					color: theme.palette.text.secondary
				}
			}
		}
	};
}
