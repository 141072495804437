import _ from 'lodash';
import { SET_WIDTH, SET_THEME_SETTINGS, SET_IS_MOBILE } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case SET_WIDTH: {
			const width = _.omit({ ...action.payload });
			return {
				...state,
				...width
			};
		}
		case SET_THEME_SETTINGS: {
			return {
				...state,
				...action.payload
			};
		}
		case SET_IS_MOBILE: {
			return {
				...state,
				isMobile: action.payload
			};
		}
		default:
			return state;
	}
}
