import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

export const getUserProfile = async () => {
	try {
		return await API.get(CONFIGURATION.AWS_API_NAME, '/users/current-user', { response: true });
	} catch (err) {
		console.log(err.response);
		return err;
	}
};

export const getCurrentTenant = async () => {
	try {
		return await API.get(CONFIGURATION.AWS_API_NAME, '/tenants/current-tenant', {
			response: true
		});
	} catch (err) {
		console.log(err);
	}
};

export const createTenant = async () => {
	try {
		return await API.post(CONFIGURATION.AWS_API_NAME, '/tenants-create/create-tenant');
	} catch (err) {
		console.log(err);
	}
};
