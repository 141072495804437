import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Loader from '../components/Loader';

// ----------------------------------------------------------------------

export default function GuestGuard({ children, defaultRoute }) {
	const { isAuthenticated } = useAuth();

	if (isAuthenticated) {
		return defaultRoute ? <Navigate to={defaultRoute} /> : <Loader />;
	}

	return children;
}
