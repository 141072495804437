import { PureComponent } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@mui/styles';
// Material components
import { Divider, Typography, Grid } from '@mui/material';

// Component styles
const styles = theme => ({
	root: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4)
	},
	company: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(0.5)
	},
	ppAndTosContainer: {
		maxWidth: '200px'
	},
	linkItem: {
		paddingBottom: theme.spacing(1)
	}
});

class Footer extends PureComponent {
	render() {
		const { classes, className } = this.props;

		const rootClassName = classNames(classes.root, className);
		const year = new Date().getFullYear();
		return (
			<div className={rootClassName}>
				<Divider />
				<Typography className={classes.company} variant="body1">
					&copy; Responsum {year}
				</Typography>

				<Grid container className={classes.ppAndTosContainer}>
					<Grid item xs={12} sm={6} className={classes.linkItem}>
						<a
							href="https://responsum.ai/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.linkItem}>
						<a href="http://responsum.ai/tos" target="_blank" rel="noopener noreferrer">
							Terms of Service
						</a>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(Footer);
