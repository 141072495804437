export default function MuiCard(theme) {
	return {
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: theme.shape.borderRadiusMd,
					position: 'inherit',
					zIndex: 0 // Fix Safari overflow: hidden with border radius
				},
				raised: {
					boxShadow: theme.customShadows.z16
				}
			}
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: { variant: 'subtitle1' },
				subheaderTypographyProps: { variant: 'body2' }
			},
			styleOverrides: {
				root: {
					padding: theme.spacing(3, 3, 0)
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: theme.spacing(2.4)
				}
			}
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: `${theme.spacing(1.7)} ${theme.spacing(2.5)}`
				}
			}
		}
	};
}
