export default function MuiTableRow(theme) {
	return {
		root: {
			height: '56px',
			'&$selected': {
				backgroundColor: theme.palette.background.default
			}
		}
	};
}
