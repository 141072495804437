import { SET_WIDTH, SET_THEME_SETTINGS, SET_IS_MOBILE } from './types';

export const setWidth = input => {
	return function (dispatch) {
		dispatch({ type: SET_WIDTH, payload: input });
	};
};

export const setIsMobile = isMobile => {
	return function (dispatch) {
		dispatch({ type: SET_IS_MOBILE, payload: isMobile });
	};
};

export const setThemeSettings = settings => {
	return function (dispatch) {
		dispatch({
			type: SET_THEME_SETTINGS,
			payload: {
				name: 'settings',
				themeMode: settings.themeMode,
				themeDirection: 'ltr'
			}
		});
	};
};
