import { APP_BAR_DESKTOP } from '../../constants';

export default function MuiToolbar() {
	return {
		MuiToolbar: {
			styleOverrides: {
				root: {
					'@media (min-width: 600px)': {
						minHeight: APP_BAR_DESKTOP
					}
				}
			}
		}
	};
}
