import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

// Define an async thunk action to fetch data
export const getActorList = createAsyncThunk('permissions/getActorList', async () => {
	const response = await API.get(CONFIGURATION.AWS_API_NAME, `/permissions/actors`, {
		response: true
	});

	if (response.status !== 200) {
		throw new Error(`HTTP error! Status: ${response.status}`);
	}
	return response.data;
});

export const setPermissions = createAsyncThunk('permissions/setPermissions', async data => {
	const { folderId, actors } = data;
	const response = await API.put(
		CONFIGURATION.AWS_API_NAME,
		`/permissions/set-resource-permissions`,
		{
			response: true,
			body: {
				resource_id: folderId,
				assigned_actors: actors
			}
		}
	);

	return response.data;
});

export const getPermissionsForResource = createAsyncThunk(
	'permissions/getPermissionsForResource',
	async id => {
		const response = await API.get(
			CONFIGURATION.AWS_API_NAME,
			`/permissions/permissions-for-resource/${id}`,
			{
				response: true
			}
		);

		return response.data;
	}
);

export const getResourceDetails = createAsyncThunk('permissions/getResourceDetails', async id => {
	const response = await API.get(CONFIGURATION.AWS_API_NAME, `/permissions/resource/${id}`, {
		response: true
	});
	return response.data;
});

// Define a slice to manage the data state
const dataSlice = createSlice({
	name: 'permissions',
	initialState: {
		data: null,
		loading: false,
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getActorList.pending, state => {
				state.loading = true;
				state.error = null;
				state.actorList = null;
			})
			.addCase(getActorList.fulfilled, (state, action) => {
				state.loading = false;
				state.actorList = action.payload;
			})
			.addCase(getActorList.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(setPermissions.pending, state => {
				state.loadingPermissions = true;
				state.error = null;
				state.permissionsSet = null;
				state.activeResourcePermissions = null;
			})
			.addCase(setPermissions.fulfilled, (state, action) => {
				state.loadingPermissions = false;
				state.permissionsSet = true;
				state.activeResourcePermissions = action.payload;
			})
			.addCase(setPermissions.rejected, (state, action) => {
				state.loadingPermissions = false;
				state.error = action.error.message;
			})
			.addCase(getPermissionsForResource.pending, state => {
				state.loadingPermissions = true;
				state.error = null;
				state.activeResourcePermissions = null;
			})
			.addCase(getPermissionsForResource.fulfilled, (state, action) => {
				state.loadingPermissions = false;
				state.activeResourcePermissions = action.payload;
			})
			.addCase(getPermissionsForResource.rejected, (state, action) => {
				state.loadingPermissions = false;
				state.error = action.error.message;
			})
			.addCase(getResourceDetails.pending, state => {
				state.loadingResource = true;
				state.error = null;
				state.activeResource = null;
			})
			.addCase(getResourceDetails.fulfilled, (state, action) => {
				state.loadinResource = false;
				state.activeResource = action.payload;
			})
			.addCase(getResourceDetails.rejected, (state, action) => {
				state.loadingResource = false;
				state.error = action.error.message;
			});
	}
});

// Export the reducer
export default dataSlice.reducer;
