// ----------------------------------------------------------------------

export default function MuiToggleButton(theme) {
	return {
		MuiToggleButton: {
			styleOverrides: {
				root: {
					color: theme.palette.grey[500],
					padding: 4,
					border: `solid 1px ${theme.palette.grey[500_32]}`,
					'&.Mui-disabled': {
						color: theme.palette.grey[500_48]
					}
				}
			}
		}
	};
}
