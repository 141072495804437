export default function MuiTableCell(theme) {
	return {
		root: {
			padding: '16px 40px 16px 24px',
			fontSize: 13
		},
		head: {
			fontSize: 14,
			background: theme.palette.background.light,
			color: theme.palette.text.primary,
			whiteSpace: 'nowrap',
			'&:first-of-type': {
				borderTopLeftRadius: 10,
				borderBottomLeftRadius: 10
			},
			'&:last-of-type': {
				borderTopRightRadius: 10,
				borderBottomRightRadius: 10
			}
		}
	};
}
