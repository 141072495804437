import {
	RESET_CONFIG_STATUS,
	UPDATE_CONFIG,
	SET_CONNECTION_STATUS,
	SET_ERROR
} from '../actions/types';

const INITIAL_STATE = { isOnline: true };

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case RESET_CONFIG_STATUS: {
			return {
				...state,
				status: null
			};
		}
		case UPDATE_CONFIG: {
			return { ...state, status: action.payload?.status };
		}

		case SET_CONNECTION_STATUS: {
			return {
				...state,
				isOnline: action.payload
			};
		}
		case SET_ERROR: {
			return {
				...state,
				...action.payload
			};
		}
		default:
			return state;
	}
}
