export default function MuiChip(theme) {
	return {
		MuiChip: {
			styleOverrides: {
				sizeSmall: {
					fontSize: '11px',
					height: '25px',
					fontWeight: '500'
				},
				label: {
					marginTop: -2
				},
				colorDefault: {
					'& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
						color: theme.palette.text.secondary
					}
				},
				filled: {
					backgroundColor: theme.palette.grey[500_12]
				}
			}
		}
	};
}
