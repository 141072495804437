import { useEffect } from 'react';
import { Box, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setIsOnline } from '../../redux/actions/commonActions';
import { LEFT_SIDEBAR_WIDTH } from '../../constants';

function OnlineStatus() {
	const dispatch = useDispatch();

	useEffect(() => {
		function onlineHandler() {
			dispatch(setIsOnline(true));
		}

		function offlineHandler() {
			dispatch(setIsOnline(false));
		}

		window.addEventListener('online', onlineHandler);
		window.addEventListener('offline', offlineHandler);

		return () => {
			window.removeEventListener('online', onlineHandler);
			window.removeEventListener('offline', offlineHandler);
		};
	}, []);

	const isOnline = useSelector(state => state.common.isOnline);
	const sidebarOpened = useSelector(state => state.layout.sidebarIsOpen);
	const margin = sidebarOpened ? LEFT_SIDEBAR_WIDTH : 0;

	return (
		!isOnline && (
			<Box
				sx={{
					position: 'fixed',
					bottom: '20px',
					left: '50%',
					transform: 'translateX(-50%)',
					paddingLeft: margin
				}}
			>
				<Alert severity="error">
					Please check your internet connection, seems like you are offline 😓
				</Alert>
			</Box>
		)
	);
}

export default OnlineStatus;
