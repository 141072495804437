// Palette

export default function MuiLinearProgress(theme) {
	return {
		root: {
			borderRadius: '3px',
			overflow: 'hidden'
		},
		colorPrimary: {
			backgroundColor: theme.palette.common.neutral
		}
	};
}
