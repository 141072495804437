import {
	GET_TOOLS,
	GET_AGENTS,
	GET_AGENT_TYPES,
	GET_MODEL_TYPES,
	CREATE_AGENT,
	GET_TENANTS,
	CLEAR_STATUS,
	GET_AGENT_BY_ID,
	CLEAR_AGENT,
	UPDATE_AGENT,
	DELETE_AGENT,
	GET_CONVERSATION_LIST,
	GET_MESSAGE_HISTORY,
	GET_ALL_AGENTS,
	GET_USERS,
	APPEND_MESSAGE_HISTORY,
	APPEND_CONVERSATION_LIST,
	POST_CRITERIA_FETCH,
	POST_CRITERIA_EVALUATION,
	POST_PAIRWISE_CRITERIA_EVALUATION,
	GET_CRITERIA,
	GET_PAIRWISE_CRITERIA,
	POST_CRITERIA,
	POST_PAIRWISE_CRITERIA,
	DELETE_CRITERIA,
	DELETE_PAIRWISE_CRITERIA,
	POST_EVALUATE_CUSTOM,
	POST_FINETUNE_MODEL,
	GET_FINETUNED_MODELS,
	POST_FEEDBACK_EVALUATION,
	GET_CURRENT_SYSTEM_PROMPT,
	POST_RESOLVE_FEEDBACK,
	POST_SHORTEN_SYSTEM_PROMPT,
	POST_DELETE_FINETUNE,
	SET_TESTING_EVALUATION_CHANGED,
	POST_ADD_GUARDRAIL,
	GET_GUARDRAILS,
	DELETE_GUARDRAIL,
	PUT_UPDATE_GUARDRAIL,
	POST_TEST_GUARDRAIL,
	GET_ALL_MODEL_TYPES,
	GET_MODEL_PROVIDERS,
	GET_MODEL_BY_ID,
	GET_MODEL_BY_ID_ERROR,
	GET_MODEL_BY_ID_PENDING
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_TOOLS: {
			return {
				...state,
				tools: action.payload
			};
		}
		case GET_AGENTS: {
			return {
				...state,
				agentList: action.payload
			};
		}
		case GET_TENANTS: {
			return {
				...state,
				tenantList: action.payload
			};
		}
		case GET_AGENT_TYPES: {
			return {
				...state,
				agentTypes: action.payload
			};
		}
		case GET_MODEL_TYPES: {
			return {
				...state,
				modelTypes: action.payload
			};
		}
		case CREATE_AGENT: {
			return {
				...state,
				agentCreateStatus: action.payload
			};
		}
		case DELETE_AGENT: {
			return {
				...state,
				agentDeleteStatus: action.payload
			};
		}
		case CLEAR_STATUS: {
			return {
				...state,
				agentCreateStatus: null,
				agentDeleteStatus: null
			};
		}
		case UPDATE_AGENT: {
			return {
				...state,
				agentCreateStatus: action.payload
			};
		}
		case GET_AGENT_BY_ID: {
			return {
				...state,
				agent: action.payload
			};
		}
		case CLEAR_AGENT: {
			return {
				...state,
				agent: null
			};
		}
		case GET_CONVERSATION_LIST: {
			return {
				...state,
				conversationList: action.payload,
				listCount: action.payload?.total_count
			};
		}
		case APPEND_CONVERSATION_LIST: {
			return {
				...state,
				conversationList: {
					...action.payload,
					histories: [...state.conversationList.histories, ...action.payload.histories]
				},
				listCount: action.payload?.total_count
			};
		}
		case GET_MESSAGE_HISTORY: {
			return {
				...state,
				conversationHistory: {
					...state.conversationHistory,
					[action.payload.id]: {
						...action.payload.messages,
						messages: action.payload.messages.messages.reverse()
					}
				}
			};
		}
		case APPEND_MESSAGE_HISTORY: {
			return {
				...state,
				conversationHistory: {
					...state.conversationHistory,
					[action.payload.id]: {
						...action.payload.messages,
						messages: [
							...action.payload.messages.messages.reverse(),
							...state.conversationHistory[action.payload.id].messages
						]
					}
				}
			};
		}
		case GET_ALL_AGENTS: {
			return {
				...state,
				allAgents: action.payload
			};
		}
		case GET_USERS: {
			return {
				...state,
				userList: action.payload
			};
		}
		case POST_CRITERIA_FETCH: {
			return {
				...state,
				postCriteriaFetchResult: action.payload.logs,
				postCriteriaNonEvaluatedCount: action.payload.nonevaluated_count
			};
		}
		case POST_CRITERIA_EVALUATION: {
			return {
				...state,
				postCriteriaEvaluation: action.payload
			};
		}
		case POST_PAIRWISE_CRITERIA_EVALUATION: {
			return {
				...state,
				aggregatedPairwiseCriteria: action.payload
			};
		}
		case GET_CRITERIA: {
			return {
				...state,
				criteria: action.payload
			};
		}
		case GET_PAIRWISE_CRITERIA: {
			return {
				...state,
				pairwiseCriteria: action.payload
			};
		}
		case POST_CRITERIA: {
			return {
				...state,
				postCriteria: action.payload
			};
		}
		case POST_PAIRWISE_CRITERIA: {
			return {
				...state,
				postPairwiseCriteria: action.payload
			};
		}
		case DELETE_CRITERIA: {
			return {
				...state,
				deleteCriteria: action.payload
			};
		}
		case DELETE_PAIRWISE_CRITERIA: {
			return {
				...state,
				deletePairwiseCriteria: action.payload
			};
		}
		case POST_EVALUATE_CUSTOM: {
			return {
				...state,
				postEvaluateCustomResult: action.payload
			};
		}
		case GET_CURRENT_SYSTEM_PROMPT: {
			return {
				...state,
				getCurrentSystemPromptResult: action.payload
			};
		}
		case POST_SHORTEN_SYSTEM_PROMPT: {
			return {
				...state,
				postShortenSystemPromptResult: action.payload
			};
		}
		case POST_RESOLVE_FEEDBACK: {
			return {
				...state,
				postResolveFeedbackResult: action.payload
			};
		}
		case POST_FEEDBACK_EVALUATION: {
			return {
				...state,
				postFeedbackEvaluationResult: action.payload
			};
		}
		case POST_FINETUNE_MODEL: {
			return {
				...state,
				postFinetuneResult: action.payload
			};
		}
		case GET_FINETUNED_MODELS: {
			return {
				...state,
				getFinetuneResult: action.payload
			};
		}
		case POST_DELETE_FINETUNE: {
			return {
				...state,
				postDeleteFinetuneResult: action.payload
			};
		}
		case SET_TESTING_EVALUATION_CHANGED: {
			return {
				...state,
				setTestingEvaluationChangedResult: action.payload
			};
		}
		case POST_ADD_GUARDRAIL: {
			return {
				...state,
				postAddGuardrailResult: action.payload
			};
		}
		case POST_TEST_GUARDRAIL: {
			return {
				...state,
				postTestGuardrailResult: action.payload
			};
		}
		case GET_GUARDRAILS: {
			return {
				...state,
				getGuardrailsResult: action.payload
			};
		}
		case DELETE_GUARDRAIL: {
			return {
				...state,
				deleteGuardrailResult: action.payload
			};
		}
		case PUT_UPDATE_GUARDRAIL: {
			return {
				...state,
				putUpdateGuardrailResult: action.payload
			};
		}
		case GET_ALL_MODEL_TYPES: {
			return {
				...state,
				llmList: action.payload
			};
		}
		case GET_MODEL_PROVIDERS: {
			return {
				...state,
				modelProviders: action.payload
			};
		}
		case GET_MODEL_BY_ID_PENDING: {
			return {
				...state,
				llmDetails: null,
				llmError: null,
				isLoading: true
			};
		}
		case GET_MODEL_BY_ID: {
			return {
				...state,
				llmDetails: action.payload,
				isLoading: false
			};
		}
		case GET_MODEL_BY_ID_ERROR: {
			return {
				...state,
				llmError: action.payload,
				isLoading: false
			};
		}
		default:
			return state;
	}
}
