import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

export default function SetNewPasswordForm() {
	const { completeNewPassword } = useAuth();
	const isMountedRef = useIsMountedRef();

	const ResetPasswordSchema = Yup.object().shape({
		password: Yup.string()
			.required('Password is required')
			.min(8)
			.max(99)
			.matches(
				/^(?=.*\d)(?=.*[\^$*.\[\]\{\}\(\)\?\-"!@#%&\/\,><':;|_~`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
				'Password should contain at least one number, special characters, uppercase and lowercase letter'
			),
		confirm_password: Yup.string()
			.required('Confirm password is required')
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.min(8)
			.max(99)
			.matches(
				/^(?=.*\d)(?=.*[\^$*.\[\]\{\}\(\)\?\-"!@#%&\/\,><':;|_~`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
				'Password should contain at least one number, special characters, uppercase and lowercase letter'
			)
	});

	const formik = useFormik({
		initialValues: {
			password: '',
			confirm_password: ''
		},
		validationSchema: ResetPasswordSchema,
		onSubmit: async (values, { setErrors, setSubmitting }) => {
			try {
				await completeNewPassword(values.password);
				if (isMountedRef.current) {
					setSubmitting(false);
				}
			} catch (error) {
				if (isMountedRef.current) {
					setErrors({ afterSubmit: error.message });
					setSubmitting(false);
				}
			}
		}
	});

	const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" onSubmit={handleSubmit}>
				<Stack spacing={3}>
					{errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
					<TextField
						fullWidth
						name="password"
						{...getFieldProps('password')}
						type="password"
						label="New password"
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
						inputProps={{ autocomplete: 'off' }}
					/>
					<TextField
						fullWidth
						name="confirm-password"
						{...getFieldProps('confirm_password')}
						type="password"
						label="Confirm new password"
						error={Boolean(touched.confirm_password && errors.confirm_password)}
						helperText={touched.confirm_password && errors.confirm_password}
						inputProps={{ autocomplete: 'off' }}
					/>
					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						loading={isSubmitting}
					>
						Reset Password
					</LoadingButton>
				</Stack>
			</Form>
		</FormikProvider>
	);
}
