/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import store from './reduxStore';
import amplifyConfig from './amplifyConfig';
import { useAuthProvider } from './hooks/useAuthProvider';

const AuthProvider = useAuthProvider();

Amplify.configure(amplifyConfig);

// function noop() {}

// console.log = noop;
// console.warn = noop;
// console.error = noop;

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<AuthProvider>
				<App />
			</AuthProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
