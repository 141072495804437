import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

// Externals
import { connect } from 'react-redux';

// Material helpers
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

// Material components
import {
	Box,
	Button,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Tooltip,
	AppBar
} from '@mui/material';

// Material icons
import { Feedback, KeyboardArrowDown as DownIcon } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import MyAvatar from '../MyAvatar';
import InviteUser from '../InviteUserForm';
import ModeToggle from './Settings/ModeToggle';
import useAuth from '../../../../hooks/useAuth';

// Component styles
import styles from './styles';

const getUserMail = user => {
	return user?.displayName?.length > 25
		? `${user?.displayName?.slice(0, 25)}...`
		: user?.displayName;
};

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(1px)',
	background: 'transparent',
	WebkitBackdropFilter: 'blur(1px)', // Fix on Mobile
	borderBottom: `1px solid ${theme.palette.divider}`
}));

function Topbar({ classes, isMobile }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openInviteDialog, setOpenInviteDialog] = useState(false);

	const { user, logout } = useAuth();

	const onSignOut = () => {
		// logout
		logout();
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toggleInviteUser = () => {
		setOpenInviteDialog(prevState => !prevState);
	};

	const identities = user?.identities ? JSON.parse(user?.identities) : [];

	return (
		<>
			<RootStyle>
				<Toolbar>
					<div className={classes.userContainer}>
						<Box display={{ xs: 'none', sm: 'block' }}>
							<div className={classes.hideShowBoxContainer}>
								<Tooltip title="Report a Bug">
									<IconButton
										onClick={() =>
											(window.location =
												'mailto:support@iolap.com?subject=Responsum Feedback')
										}
										sx={{ marginRight: '10px' }}
										size="small"
									>
										<Feedback fontSize="small" />
									</IconButton>
								</Tooltip>
								<ModeToggle />
							</div>
						</Box>
						<Button
							aria-owns={anchorEl ? 'simple-menu' : undefined}
							aria-haspopup="true"
							onClick={handleClick}
							className={classes.navbarUsername}
						>
							<MyAvatar />
							{!isMobile && (
								<>
									<div className={classes.userDetailsTopbar}>
										{getUserMail(user)}

										{user?.role && (
											<>
												<br />
												<Box display="flex">
													<Typography variant="caption">
														Role:{' '}
													</Typography>
													<Typography
														sx={{
															ml: 0.3,
															textTransform: 'capitalize'
														}}
														variant="caption"
													>
														{user?.role}{' '}
													</Typography>
												</Box>
											</>
										)}
									</div>
									<DownIcon className={classes.navbarUsernameDropdown} />
								</>
							)}
						</Button>

						<Menu
							anchorEl={anchorEl}
							classes={{
								paper: classes.dropdownMenu,
								list: classes.dropdownMenuList
							}}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
							transformOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{Boolean(!identities.length) && (
								<NavLink
									to="/dashboard/profile"
									className={classes.dropdownMenuLink}
								>
									<MenuItem
										onClick={handleClose}
										className={classes.dropdownMenuItem}
									>
										<div className={classes.iconAndText}>
											<Icon fontSize={18} icon="iconoir:profile-circle" />
											<Typography className={classes.dropdownMenuText}>
												Profile
											</Typography>
										</div>
									</MenuItem>
								</NavLink>
							)}

							<Link
								href="https://responsum.ai/privacy-policy/"
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								className={classes.dropdownMenuLink}
							>
								<MenuItem
									onClick={handleClose}
									className={classes.dropdownMenuItem}
								>
									<div
										style={{ textDecoration: 'none' }}
										className={classes.iconAndText}
									>
										<Icon fontSize={18} icon="iconoir:privacy-policy" />
										<Typography className={classes.dropdownMenuText}>
											Privacy Policy
										</Typography>
									</div>
								</MenuItem>
							</Link>
							<MenuItem
								onClick={onSignOut}
								classes={{ root: classes.dropdownMenuItem }}
							>
								<div className={classes.iconAndText}>
									<Icon fontSize={18} icon="material-symbols:logout" />
									<Typography className={classes.dropdownMenuText}>
										Logout
									</Typography>
								</div>
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</RootStyle>
			<InviteUser openDialog={openInviteDialog} toggleRightSidebar={toggleInviteUser} />
		</>
	);
}

export default connect()(withStyles(styles, { withTheme: true })(Topbar));
