import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_NOLA_BOT_URL;

let socket;

export const initiateSocket = accessToken => {
	socket = io(SOCKET_URL, {
		path: '/ws/socket.io',
		auth: {
			token: accessToken
		}
	});

	return socket;
};

export const disconnectSocket = () => {
	if (socket) socket.disconnect();
};

export const connectSocket = accessToken => {
	if (socket) {
		socket.auth.token = accessToken;
		socket.connect();
	}
};

export const emitMessage = (key, agent_id, conversation_id, message, callback) => {
	console.log('emit message', message, agent_id);
	socket.emit(
		key,
		{
			...message,
			agent_id,
			conversation_id
		},
		response => {
			callback(response, message, key);
		}
	);
};

export const subscribeToEvent = (eventKey, callback) => {
	if (socket) {
		socket.on(eventKey, callback);
	}
};

export const removeSubscription = (eventKey, callback) => {
	if (socket) {
		socket.off(eventKey, callback);
	}
};
