import { API } from 'aws-amplify';
import {
	FETCH_MY_CONTACTS,
	REINVITE_USER,
	DELETE_USER,
	DELETE_USER_ERROR,
	CHANGE_USER_ROLE,
	INVITE_USER_PENDING,
	INVITE_USER_SUCCESS,
	INVITE_USER_FAILURE
} from './types';

import CONFIGURATION from '../../app_configuration';

export const deleteUser = users => async dispatch => {
	const data = {
		response: true,
		body: users
	};

	try {
		const response = await API.del(CONFIGURATION.AWS_API_NAME, `/users`, data);
		dispatch({
			type: DELETE_USER,
			payload: response
		});
	} catch (err) {
		dispatch({
			type: DELETE_USER_ERROR
		});
	}
};

export const refreshDeletionStatus = () => async dispatch => {
	dispatch({
		type: DELETE_USER,
		payload: { status: null }
	});
};

export const fetchMyContacts = () => async dispatch => {
	const data = {
		response: true
	};

	let res = {};
	try {
		res = await API.get(CONFIGURATION.AWS_API_NAME, '/users', data);
	} catch (err) {
		res.status = 500;
	}

	dispatch({
		type: FETCH_MY_CONTACTS,
		payload: res
	});
};

export const inviteUser = props => async dispatch => {
	dispatch({
		type: INVITE_USER_PENDING
	});
	const data = {
		body: {
			role: props.invitedUser.userType,
			email: props.invitedUser.email
		},
		response: true
	};
	try {
		await API.post(CONFIGURATION.AWS_API_NAME, `/users/invite`, data);
		dispatch({
			type: INVITE_USER_SUCCESS,
			payload: { message: `User ${props.invitedUser.email} successfully invited` }
		});
	} catch (err) {
		dispatch({
			type: INVITE_USER_FAILURE,
			payload: {
				message:
					err.response.data.detail ??
					'Oops! There was an error while trying to invite user. Please try again later.'
			}
		});
	}
};

export const reinviteUser = userEmail => async dispatch => {
	const action = {
		type: REINVITE_USER,
		payload: { status: 500 }
	};

	try {
		const requestData = {
			body: {
				email: userEmail
			},
			response: true
		};
		const response = await API.put(CONFIGURATION.AWS_API_NAME, `/users/invite`, requestData);
		action.payload.status = response.status;
	} catch (err) {
		console.log(err);
	}

	dispatch(action);
};

export const changeUserRole = (userId, newRole) => async dispatch => {
	const action = {
		type: CHANGE_USER_ROLE,
		payload: { status: 500 }
	};
	console.log(userId);
	try {
		const requestData = {
			body: {
				role: newRole,
				id: userId
			},
			response: true
		};
		const response = await API.patch(CONFIGURATION.AWS_API_NAME, `/users/user`, requestData);

		action.payload.status = response.status;
	} catch (err) {
		console.log(err);
	}

	dispatch(action);
};
