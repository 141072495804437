// hooks
import useAuth from '../../../hooks/useAuth';
//
import { MAvatar } from '../../../components/@material-extend';
import createAvatar from '../../../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
	const { user } = useAuth();

	return (
		<MAvatar
			sx={{ p: 0.4 }}
			src={user?.picture}
			alt={user?.email}
			color={user?.picture ? 'default' : createAvatar(user?.displayName).color}
			{...other}
		>
			{createAvatar(user?.displayName).name}
		</MAvatar>
	);
}
